
//docReady(function () {
//    // DOM is loaded and ready for manipulation here

//});

// Equivalent to document ready function
function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1)
    } else {
        document.addEventListener("DOMContentLoaded", fn)
    }
}

function getResources(reqCulture) {
    if (localStorage.getItem("resources") === null) {
        getAjaxResources(reqCulture);
    }
    else {
        if (localStorage.getItem("curCulture") !== null && localStorage.getItem("curCulture") !== reqCulture) {
            getAjaxResources(reqCulture);
        }
    }

}

function getAjaxResources(reqCulture) {
    $.ajax({
        cache: false,
        type: "GET",
        url: "/Resources/getResources",
        data: {
            "culture": reqCulture
        },
        content: "application/json; charset=utf-8",
        dataType: "json",
        success: function (d) {
            localStorage.setItem("curCulture", reqCulture);
            localStorage.setItem("resources", d.resources);
            //console.log(resources)
        },
        error: function (d) {
            alert("error getting resources");
        }
    });
}

function getRes(p, n) {
    return JSON.parse(localStorage.getItem("resources")).filter(item => item.page == p && item.name == n)[0].resource;
}

/* get parameter by name from url query string */
function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function addToCart(event) {
    event.preventDefault();
    let dt = {
        tEntityID: document.querySelector("#tEntityID").value,
        productId: document.querySelector("#productID").value,
        OrderQuantity: document.querySelector("#orderQuantity").value,
        MediaID: document.querySelector("#mediaID").value,
        MediaUrl: document.querySelector("#mediaUrl").value,
        CartNotes: document.querySelector("#cartNotes").value,
        UserNotes: document.querySelector("#userNotes").value,
        SellingPrice: document.querySelector(".sellingPrice").dataset.sellingprice,
        Price: document.querySelector("#cartPrice").value,
        CartDescription: document.querySelector(".cartDescription").dataset.cartdescription
    };
    $.ajax({
        cache: false,
        type: "POST",
        url: "/ShoppingCart/AddToCart",
        content: "application/json; charset=utf-8",

        data:
        {
           "dt" :  JSON.stringify(dt)
        },
        success: function (d) {
            if (d.success) {
                updateBasket(loadBasketItems);
                toastr.success(d.message);
            }
            else {
                toastr.error(d.message);
            }
        },
        error: function (d) {
            toastr.error("error saving/updating");
        }
    });
}

function removeBasketItem(id) {
    let curl = window.location.href.toLowerCase()
    let f = 0
    let f1 = curl.indexOf("ShoppingCart/shoppingcart".toLowerCase())
    let f2 = curl.indexOf("ShoppingCart/checkout".toLowerCase())
    f = f1 + f2
    if (f == -2) {
        $.ajax({
            cache: false,
            type: "POST",
            url: "/ShoppingCart/DeleteCartItem",
            content: "application/json; charset=utf-8",
            dataType: "json",
            data: {
                ID: id
            },
            success: function (d) {
                updateBasket(loadBasketItems);
            },
            error: function (d) {
                alert("error while removing");
            }
        });
    }
}

function updateBasket(callback) {
    $.ajax({
        type: "GET",
        url: "/ShoppingCart/GetCountAndTotal",
        content: "application/json; charset=utf-8",
        dataType: "json",
        success: function (d) {
            $(".cart-count").empty().append(d.Count);
            $(".cart-count").show();
            callback();
        },
        error: function (d) {
            $(".cart-count").empty().append(d.Count);
            $(".cart-count").show();
            alert(d.error);

        }
    }); 
}

function loadBasketItems() {
    $(".cart-item-list").load("/ShoppingCart/Basket?entitySectionID=" + window.reqEntitySectionID + "&lang=" + window.reqCulture);
}

function checkValidPeriod(d1, d2) {
    let d = new Date().toISOString()
    d1 = d1.replace("/Date(", "").replace(")/", "") //stip everything but the digits. Regular Expression can be used instead.
    d1 = new Date(parseInt(d1)).toISOString();
    d2 = d2.replace("/Date(", "").replace(")/", "") 
    d2 = new Date(parseInt(d2)).toISOString();
    if (d >= d1 && d <= d2)
        return true
    else
        return false
}

// distinct values of an array. Usage (v is the variable and a is the array to filter) v = a.filter(distinct)
function distinct(value, index, self) {
    return self.indexOf(value) === index;
}

// Swiper Setup
var swiperObjs = [];
var sliderBreakPoint = 991; // It will effect when you have used attribute "data-thumb-slider-md-direction" OR "data-slider-md-direction"
/****** Get window width ******/
function getWindowWidth() {
    return $(window).width();
}

/****** Get window height ******/
function getWindowHeight() {
    return $(window).height();
}

/****** Setup swiper slider ******/
function setupSwiper() {

    /****** Swiper slider using params ******/
    $('.swiper-container:not( .instafeed-wrapper )').each(function () {
        var _this = $(this),
            sliderOptions = _this.attr('data-slider-options');
        if (typeof (sliderOptions) !== 'undefined' && sliderOptions !== null) {

            sliderOptions = $.parseJSON(sliderOptions);

            /* If user have provided "data-slider-md-direction" attribute then below code will execute */
            var mdDirection = _this.attr('data-slider-md-direction');
            if (mdDirection != '' && mdDirection != undefined) {

                var direction = (sliderOptions['direction'] != '' && sliderOptions['direction'] != undefined) ? sliderOptions['direction'] : mdDirection;
                sliderOptions['on'] = {
                    init: function () {
                        if (getWindowWidth() <= sliderBreakPoint) {
                            this.changeDirection(mdDirection);
                        } else {
                            this.changeDirection(direction);
                        }
                        this.update();
                    },
                    resize: function () {
                        if (getWindowWidth() <= sliderBreakPoint) {
                            this.changeDirection(mdDirection);
                        } else {
                            this.changeDirection(direction);
                        }
                        this.update();
                    }
                };
            }

            /* If user have provided "data-thumb-slider-md-direction" attribute then below code will execute */
            if (sliderOptions['thumbs'] != '' && sliderOptions['thumbs'] != undefined) {

                var mdThumbDirection = _this.attr('data-thumb-slider-md-direction');
                if (mdThumbDirection != '' && mdThumbDirection != undefined) {

                    var thumbDirection = (sliderOptions['thumbs']['swiper']['direction'] != '' && sliderOptions['thumbs']['swiper']['direction'] != undefined) ? sliderOptions['thumbs']['swiper']['direction'] : mdThumbDirection;
                    sliderOptions['thumbs']['swiper']['on'] = {
                        init: function () {
                            if (getWindowWidth() <= sliderBreakPoint) {
                                this.changeDirection(mdThumbDirection);
                            } else {
                                this.changeDirection(thumbDirection);
                            }
                            this.update();
                        },
                        resize: function () {
                            if (getWindowWidth() <= sliderBreakPoint) {
                                this.changeDirection(mdThumbDirection);
                            } else {
                                this.changeDirection(thumbDirection);
                            }
                            this.update();
                        },
                        click: function () {
                            /* Product thumbs automatic next / previous on click slide */
                            if (this.activeIndex == this.clickedIndex) {
                                this.slidePrev();
                            } else {
                                this.slideNext();
                            }
                        }
                    };
                }
            }

            /* If user have provided "data-slider-number-pagination" attribute then below code will execute */
            var numberPagination = _this.attr('data-slider-number-pagination');
            if (numberPagination != '' && numberPagination != undefined) {

                sliderOptions['on']['slideChange'] = function () {
                    if ($('.swiper-pagination-current').length > 0) {
                        $('.swiper-pagination-current').html(pad(this.realIndex + 1, 2));
                    }
                    if ($('.swiper-pagination-total').length > 0) {
                        $('.swiper-pagination-total').html(pad(this.slides.length - 2, 2));
                    }
                };
            }

            /* If user have provided "data-slide-change-on-click" attribute then below code will execute */
            var changeOnClick = _this.attr('data-slide-change-on-click');
            if (changeOnClick != '' && changeOnClick != undefined) {

                sliderOptions['on'] = {
                    click: function () {
                        if (this.activeIndex > this.clickedIndex) {
                            this.slidePrev();
                        } else if (this.activeIndex < this.clickedIndex) {
                            this.slideNext();
                        }
                    }
                };
            }

            /* If user have provided "data-thumbs" attribute then below code will execute */
            var dataThumbs = _this.attr('data-thumbs');
            if (dataThumbs != '' && dataThumbs != undefined) {
                dataThumbs = $.parseJSON(dataThumbs);
                if (typeof (dataThumbs) !== 'undefined' && dataThumbs !== null) {
                    sliderOptions['pagination']['renderBullet'] = function (index, className) {
                        return '<span class="' + className + '" style="background-image: url( ' + dataThumbs[index] + ' )"></span>';
                    }
                }
            }

            var swiperObj = new Swiper(_this, sliderOptions);
            swiperObjs.push(swiperObj);
        }
    });
}

/****** Destroy swiper loop ******/
function destroySwiperLoop() {
    for (var i = 0; i < swiperObjs.length; i++) {
        var swiperObj = swiperObjs[i],
            destroyWidth = swiperObj.$el.attr('data-slider-destroy');
        // If user have provided "data-slider-destroy" attribute then below code will execute
        if (destroyWidth != '' && destroyWidth != undefined) {
            if (getWindowWidth() <= destroyWidth) {
                swiperObj.destroy(false, true); // Destroy swiper
            } else if (swiperObj.destroyed) {
                swiperObjs.splice(i, 1);
                setupSwiper(); // Initialize swiper again
            }
        }
    };
}

/****** Reset swiper loop ******/
function resetSwiperLoop() {
    setTimeout(function () {
        for (var i = 0; i < swiperObjs.length; i++) {
            var swiperObj = swiperObjs[i];
            swiperObj.update();
        }
    }, 500);
}

function updateJsonLD(product, jsEntity, calculatedSellingPrice) {

    let jsonLdScript = document.querySelector('script[type="application/ld+json"]');
    let jsonld = JSON.parse(jsonLdScript.innerText);
    let media
    let mediaList = []
    if (jsEntity.ShortDescription != null) {
        jsonld.description = jsEntity.ShortDescription.replace(/(<([^>]+)>)/gi, "")
    }
    if (jsEntity.Article != null) {
        jsonld.description += " " + jsEntity.Article.replace(/(<([^>]+)>)/gi, "")
    }
    jsonld.url = window.location.href.toString()

    if (product.IsDynamic) {
        let mediaTitle = ""
        if (window.mediaLibraryMedia != null && window.mediaLibraryMedia != undefined)
            mediaTitle = window.mediaLibraryMedia.Title != null ? " " + window.mediaLibraryMedia.Title : ""
        jsonld.name = jsEntity.Title + "-" + getParameterByName("sId") + mediaTitle
        mediaList.push(window.location.protocol + "//" + window.location.hostname + window.mediaLibraryMedia.Url)
        jsonld.image = mediaList
        if (calculatedSellingPrice != undefined)
            jsonld.offers.price = calculatedSellingPrice.toString()
        else
            jsonld.offers.price = "Custom"
    }
    else { // Not Dynamic
        jsonld.name = jsEntity.Title
        media = product.ProductMedias
        if (media.length > 0) {
            for (var i = 0; i < media.length; i++) {
                mediaList.push(window.location.protocol + "//" + window.location.hostname + media[i].Url)
            }
            jsonld.image = mediaList
        }
        jsonld.offers.price = product.SellingPrice
    }

    let newJson = JSON.stringify(jsonld)
    console.log(newJson)
    jsonLdScript.innerHTML = newJson
}
